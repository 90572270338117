const MULTISELECT_LEFT_MARGIN = 600;
const ALL_VARIATIONS = ['All', 'all', 'Tous', 'Todos', 'Tutti'];
let timer = null;
const selectorsIds = [
  'categorySelector',
  'librarySelector',
  'languageSelector',
  'authorSelector',
  'imprintSelector',
  'collectionsSelector'
];

// Fields with multiselect component
export const multipleSelectFields = {
  'book_formats': 'name',
  'markets': 'id',
  'providers': null,
  'catalog_types': 'value',
  'audience': 'name',
  'accessibilities': 'name'
}
// Open fields
export const openFields = [
  'isbn',
  'title_subtitle',
  'offer_id'
];
// Dynamic fields
export const dynamicFields = [
  'category',
  'library_id',
  'lang',
  'author_id',
  'imprint_id',
  'collection_id',
  'publishing_group_id',
  'serie_id',
  'list_id',
  'award_id'
];
// Select fields
export const selectFields = [
  'influence',
  'from_quebec',
  'cover',
  'network_id',
  'territory_state',
  'availability',
  'catalogued'
];

const availableFiltersAndColumns = [
  'activeFilters',
  'inactiveFilters',
  'orderFilters',
  'activeColumns',
  'inactiveColumns',
  'orderColumns'
];

// Debouncer:
const DEBOUNCE_TIME = 1300;
const MIN_TEXT_LENGTH = 2;
let debounceRunning = false;

export const clearFiltersAndColumnsCache = function () {
  availableFiltersAndColumns.forEach((filter) => {
    localStorage.removeItem(filter);
  });
}

export const cleanUpFields = function (params, condition = null) {
  let cleanParams = {};
  if (Object.keys(params).length !== 0) {
    for (let key in params) {
      if (params[key] !== condition && params[key] !== '') {
        if (Array.isArray(params[key])) {
          if (params[key].length > 0) {
            const value = params[key][Object.keys(params[key])[0]];
            if (value !== 'All') {
              cleanParams[key] = value;
            }
          }
        } else {
          cleanParams[key] = params[key];
        }
      }
    }
  }

  return cleanParams;
}

export const mapInfluences = function (influences) {
  const all_influences = [];
  const mapped = influences.map((influence) => {
    return {
      id: influence.id,
      name: influence.attributes.name,
      code: influence.attributes.code,
      parent_id: influence.attributes.parent_id
    }
  });
  const parents = mapped.filter((influence) => influence.parent_id === null);
  parents.forEach((parent) => {
    all_influences.push(parent);
    mapped.forEach((influence) => {
      if (influence.parent_id === parseInt(parent.id)) {
        all_influences.push(influence);
      }
    });
  });
  return all_influences;
}

export const debounce = function (text) {
  if (debounceRunning || text.length < MIN_TEXT_LENGTH) return Promise.resolve(false);
  debounceRunning = true;
  return new Promise((resolve) => {
    if (timer === null) {
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        debounceRunning = false;
        resolve(true);
      }, DEBOUNCE_TIME);
    }
  });
}

export const mapSettings = function (settings, userId) {
  let sharedSettings = settings.filter(
    (setting) => setting.attributes.general_access === 'shared'
  );
  let ownSettings = settings.filter(
    (setting) => setting.relationships.user.data.id === userId
  );
  const allSettings = sharedSettings.concat(ownSettings);

  return allSettings.map((setting) => {
    return {
      id: parseInt(setting.id),
      name: setting.attributes.name,
      generalAccess: setting.attributes.general_access,
      filtersUrlQuery: setting.attributes.filters_url_query,
      filterPositions: setting.attributes.filter_positions,
      columnPositions: setting.attributes.column_positions,
      user: setting.relationships.user.data.id
    }
  });
}

export const mapAuthors = function (authors) {
  const all_authors = authors.map((author) => {
    return {
      id: author.id,
      name: author.attributes.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      nameOriginal: author.attributes.fullname
    }
  });
  return all_authors;
}


export const mapProviders = function (providers) {
  return providers.map((provider) => {
    return provider.name
  });
}

export const mapAttrName = function (items) {
  return items.map((item) => {
    return {
      id: item.id,
      name: item.attributes.name
    }
  });
}

export const mapAudiences = function (audiences) {
  return audiences.map((audience) => {
    return {
      id: audience.id,
      name: audience.label
    }
  });
}

export const mapSeries = function (series) {
  return series.map((serie) => {
    return {
      id: serie.id,
      name: serie.attributes.original_name
    }
  });
}

export const mapLanguages = function (languages, selectedLanguage) {
  const all_languages = languages.map((language) => {
    const langCorrected = selectedLanguage == 'en' ? language['name_en'] : language[`name_${selectedLanguage}`]
    return {
      id: language.id,
      alpha2: language.alpha2 !== null ? language.alpha2 : language.iso_639_2,
      name: langCorrected,
      nameOriginal: language.name_original,
      nonAccented: langCorrected.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    }
  });
  return all_languages.sort((a, b) => a.name.localeCompare(b.name));
}

export const mapCategories = function (categories, lang, searchStr) {
  if (!categories || categories.length == 0) return [];
  const categories_mapped = [];
  categories.forEach((category) => {
    categories_mapped.push({
      code: category.attributes.code,
      name: category.attributes[`name_${lang}`],
      map: category.attributes.tree[lang]
    });
  });

  return categories_mapped.sort((a) => {
    const mapLastWord = a.map.split(' ').pop();
    return mapLastWord.toLowerCase().includes(searchStr.toLowerCase()) ? -1 : 1;
  });
}

export const obtainValues = function (arr, key) {
  const values = arr.map((item) => {
    return ALL_VARIATIONS.includes(item[key]) ? 'All' : item[key];
  });
  return values.filter((item) => item !== null);
};

export const translateAll = function (allArr) {
  return allArr.map((item) => {
    return ALL_VARIATIONS.includes(item) ? 'All' : item;
  });
}

export const obtainMarkets = function (markets) {
  const all_markets = markets.map((market) => {
    return market.id
  });
  return all_markets;
};

export const preventAllRemoval = function (e, multiSelect, field, multiselect_arrays) {
  const arr = multiselect_arrays.find(item => item.name === field);
  if (ALL_VARIATIONS.includes(e) || multiSelect[field].length === 0) {
    multiSelect[field] = arr.arr;
  }
};

export const setSelectorsMargin = function () {
  selectorsIds.forEach((selectorId) => {
    const element = document.getElementById(selectorId);
    if (!element) return;
    const selector = element.getBoundingClientRect();
    if (selector.right > MULTISELECT_LEFT_MARGIN) {
      element.nextElementSibling.classList.add('menu_left');
    } else {
      element.nextElementSibling.classList.add('menu_right');
    }
  });
};

export const findLibrary = async function (
  libraryId,
  libraryStore,
  toRaw,
  selected,
  multiselect,
  t,
  loadedOptions,
  selectedLibraryName
) {
  if (libraryId !== null) {
    await libraryStore.fetchCatalogPublicationLibrary(parseInt(libraryId));
    const libraryData = toRaw(libraryStore.data.data.attributes);
    selected.value.network_id = libraryData.network_id;
    selectedLibraryName.value = libraryData.name;

    // Set territory and state
    if (libraryData.territory_id && libraryData.state) {
      selected.value.territory_state = `${libraryData.territory_id.toLowerCase()}_${libraryData.state.toLowerCase()}`;
    } else if (libraryData.territory_id && libraryData.state === '') {
      selected.value.territory_state = libraryData.territory_id.toLowerCase();
    }

    // Set multiselect values for markets and connectors
    multiselect.value.markets = libraryData.markets.map(
      market => ({ name: t(`components.catalog_publication.filters.market_${market}`), id: market })
    );
    multiselect.value.connectors = libraryData.connectors.map(
      connector => ({ name: connector, id: connector })
    );

    //clear library catalog's options
    loadedOptions.value.libraries = [];
  }
}

export const dynamicSearchStandalone = async function (
  event,
  loadedOptions,
  store,
  toRaw,
  fetcher,
  lang,
  mapper,
  t,
  title
) {
  const loader = document.getElementById(`${title}_loader`);
  debounce(event.target.value).then(async (valid) => {
    if (!valid) return;
    loadedOptions.value = [];
    loader.innerHTML = '';
    loader.classList.add('spinner-border');
    await store[fetcher](event.target.value);
    loader.classList.remove('spinner-border');
    setTimeout(() => {
      if (mapper && lang) {
        const mappedResults = mapper(
          Array.from(toRaw(store.data.data)),
          lang
        );
        if (Object.keys(mappedResults).length === 0) setNotFound(loader, t)
        loadedOptions.value = mappedResults;
      } else {
        const results = Array.from(toRaw(store.data.data));
        if (Object.keys(results).length === 0) setNotFound(loader, t)
        loadedOptions.value = results;
      }
    }, 100);
  });
}

export const dynamicSearch = async function (
  event,
  loadedOptions,
  store,
  toRaw,
  loaderElement,
  loadedOption,
  fetcher,
  mapper,
  lang,
  t
) {
  const loader = document.getElementById(loaderElement);
  debounce(event.target.value).then(async (valid) => {
    if (!valid) return;
    loadedOptions.value[loadedOption] = [];
    loader.innerHTML = '';
    loader.classList.add('spinner-border');
    await store[fetcher](event.target.value);
    loader.classList.remove('spinner-border');
    setTimeout(() => {
      if (mapper && lang) {
        const mappedResults = mapper(
          Array.from(toRaw(store.data.data)),
          lang
        );
        if (Object.keys(mappedResults).length === 0) setNotFound(loader, t)
        loadedOptions.value[loadedOption] = mappedResults;
      } else {
        const results = Array.from(toRaw(store.data.data));
        if (Object.keys(results).length === 0) setNotFound(loader, t)
        loadedOptions.value[loadedOption] = results;
      }
    }, 100);
  });
}

const setNotFound = function (loader, t) {
  loader.innerHTML = `<small>${t('components.catalog_publication.filters.not_found')}</small>`;
  setTimeout(() => {
    loader.innerHTML = '';
  }, 2000);
}

export const categorySearchTrigger = async function (
  value,
  lang,
  toRaw,
  loadedOptions,
  categoriesStore,
  title
) {
  const loaderCategories = document.getElementById(`${title}_loader`);
  loaderCategories.classList.add('spinner-border');
  await categoriesStore.fetchCatalogPublicationCategories(value, lang);
  loaderCategories.classList.remove('spinner-border');
  loadedOptions.value.categories = [];
  const mappedCategories = mapCategories(
    Array.from(toRaw(categoriesStore.data.data)),
    lang,
    value
  );
  setTimeout(() => {
    loadedOptions.value.categories = mappedCategories;
  }, 100);
}

export const setDefaultValues = function (multiselect, selected, t) {
  selected.value.availability = 'available';
  selected.value.influence = 'All';
  multiselect.value.providers = [t('components.catalog_publication.filters.all')];
  multiselect.value.audiences = [{ id: null, name: t('components.catalog_publication.filters.all') }];
  multiselect.value.book_formats = [{ id: 1, name: t('components.catalog_publication.filters.all'), key: null }];
  multiselect.value.catalog_types = [{ value: 'traditional', name: t('components.catalog_publication.filters.catalog_type_traditional') }];
}

export const selectAllOrSeveral = (option, type, multiselect) => {
  const id = multipleSelectFields[type];
  if (id === null ? ALL_VARIATIONS.includes(option) : ALL_VARIATIONS.includes(option[id])) {
    multiselect[type] = multiselect[type].filter(
      (item) => ALL_VARIATIONS.includes(id === null ? item : item[id])
    );
  } else {
    const filter = multiselect[type].filter(
      (item) => !ALL_VARIATIONS.includes(id === null ? item : item[id])
    );
    multiselect[type] = filter;
  }
}

export const getQueryParams = (queryParams, fromStore = false) => {
  const params = fromStore ? new URLSearchParams(queryParams) : queryParams;
  const arrayQuery = [];

  if (fromStore) {
    params.forEach((value, key) => {
      arrayQuery.push(`<b>${key}</b>= ${value};`);
    });
  } else {
    for (const key in params) {
      if (params[key] !== 'All' && params[key] !== '') {
        arrayQuery.push(`<b>${key}</b>= ${params[key]};`);
      }
    }
  }
  return arrayQuery.join(' ');
};
