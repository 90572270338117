export default function (Component) {
  Component.__i18nGlobal = Component.__i18nGlobal || []
  Component.__i18nGlobal.push({
    "locale": "",
    "resource": {
      "it": {
        "search": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
          "object_types": {
            "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
            "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
            "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
            "contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
            "award": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award"])}
          }
        },
        "book_format": {
          "name": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
            "epub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPUB"])},
            "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
            "audiobook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiolibro"])},
            "mp3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiolibro"])},
            "epub_fixed_layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPUB (layout fisso)"])}
          }
        },
        "offers": {
          "other_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre licenze per questa pubblicazione"])},
          "incompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo portale di prestiti non è compatibile con questa risorsa"])},
          "previous_purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua organizzazione ha acquistato una o più licenze per questa risorsa"])},
          "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo libro è incluso nella collezione condivisa"])},
          "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dettagli"])},
          "more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Più dettagli"])},
          "fewer_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno dettagli"])},
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disable"])},
          "disable_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are-you sure you want to disable this offer? This operation cannot be reversed. It's also possible this offer will be enabled again following a future reimportation."])},
          "admin": {
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi risorsa dal carrello"])},
            "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova a ordinare questa risorsa"])},
            "current_price": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prezzo attuale dell'offerta ", _interpolate(_named("price"))])},
            "new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo prezzo"])},
            "update_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
            "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattiva l'offerta"])}
          },
          "carts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
            "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
            "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended Cart"])},
            "disclaimer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These recommendations are made by"])},
            "disclaimer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They can include **popular books in your library** that need their license renewed, **books that are not in your catalog** that might interest readers based on their preferences, books **popular in other libraries**, but not in your catalog."])},
            "banner_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 easy ways to improve your catalog"])},
            "banner_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover baskets already created to make your life easier. In these baskets are books that are:"])},
            "banner_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. The most popular in your library whose license needs to be renewed"])},
            "banner_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Absent from your catalog that might interest your readers based on their preferences"])},
            "banner_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Popular in other libraries, but are not in your catalog"])},
            "customer_service_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service Cart"])},
            "customer_service_text_html": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This cart was validated by our Customer Success team. ", _interpolate(_named("br")), " Copies for all titles below are available in your catalog but will not be invoiced to your organization."])}
          },
          "cart_orders": {
            "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro"])},
            "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creata il"])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
            "distributor_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributore dell'offerta"])},
            "formatted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formattato"])},
            "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificante del carrello"])},
            "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificante dell'ordine"])},
            "provider_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider dell'offerta"])},
            "provider_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider della risorsa"])},
            "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri della ricerca"])},
            "raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lordo"])},
            "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposta"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TO_TRANSLATE"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
            "url_unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL sconosciuto"])}
          }
        },
        "cart": {
          "refund": {
            "reason": {
              "faulty_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faulty files"])},
              "ordered_for_the_wrong_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered for the wrong library"])},
              "technical_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical issue"])},
              "operational_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational error"])},
              "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])}
            },
            "select_a_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a reason"])},
            "refund_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund mode"])},
            "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
            "button_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
            "confirm_window": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm refund"])},
              "table": {
                "unit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit price"])},
                "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
                "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
                "refund_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund amount"])},
                "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])}
              },
              "form": {
                "label_adicional_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details to include in client notification email (optional)"])},
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant context for clients (library, network, retailer, etc.)"])},
                "button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
                "button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm refund"])}
              }
            },
            "refund_copies": {
              "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Refunded Copy"]), _normalize(["Refunded Copies"])])},
              "message_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download refund summary"])},
              "date_of_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of refund"])}
            },
            "refund_cofirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund confirmed"])}
          },
          "confirm_validation": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As all sales are **final and non-refundable**, please take a moment to make sure this cart perfectly matches what you want to buy."])},
            "particularly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particularly"])},
            "list": {
              "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prices"])},
              "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantities"])},
              "formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formats"])},
              "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["license types"])}
            },
            "checkbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand and confirm that all items in my order are in accordance with my intent to purchase."])}
          },
          "license": {
            "no_other_license_type_is_available_for_this_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other license type is currently available for this publication"])},
            "select_the_desired_license_type_and_click_the_replace_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the desired license type and click the Replace button"])},
            "see_other_available_license_types_for_this_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See other available license types for this publication"])}
          },
          "move_cart": {
            "available_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteche disponibili"])},
            "available_library_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti della biblioteca"])},
            "confirm_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desideri veramente inserire questi articoli nel carrello?"])},
            "manage_users": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente associato a questa biblioteca"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione degli utenti"])}
            },
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferisci a un'altra biblioteca"])}
          },
          "retailer": {
            "retailers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivenditori"])}
          },
          "sub_library": {
            "sub_libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatturazione avanzata"])}
          },
          "item_status": {
            "already_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta già acquistata"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
            "in_another_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta già presente in un altro carrello"])},
            "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborsato"])},
            "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convalidato"])}
          },
          "unavailable_offers": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno un titolo tra quelli contenuti nel carrello non è più disponibile: le condizioni di vendita sono cambiate da quando è stato aggiunto al carrello."])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di eliminare tutti questi titoli dal carrello per procedere alla sua convalida:"])},
            "delete_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina questi titoli"])},
            "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci scusiamo per l'inconveniente."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazione importante relativa al carrello"])},
            "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
            "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])}
          },
          "incompatible_offers": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service carts may only contain titles coming from a source operated by De Marque."])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must remove the following titles from the cart before it can be validated:"])},
            "delete_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete these titles"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart validation failed"])}
          },
          "loans": {
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 prestito"])},
            "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " prestiti"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestiti illimitati"])},
            "loan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 loan"]), _normalize([_interpolate(_named("count")), " loans"])])}
          },
          "loans_concurrent_users": {
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno alla volta"])},
            "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " prestiti simultanei"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illimitati"])}
          },
          "loan_rules": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole del prestito"])},
            "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di prestiti"])},
            "concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti simultanei"])},
            "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata della licenza"])},
            "max_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata massima della licenza"])}
          },
          "loans_lifetime": {
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata illimitata"])}
          },
          "lifetime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspettativa di vita"])},
            "name": {
              "one_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una settimana"])},
              "one_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un mese"])},
              "two_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due mesi"])},
              "one_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un anno"])},
              "two_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due anni"])},
              "two_to_five_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da due a cinque anni"])},
              "five_to_ten_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da cinque a dieci anni"])},
              "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illimitato"])}
            }
          },
          "datetime": {
            "distance_in_words": {
              "half_a_minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mezzo minuto"])},
              "less_than_x_seconds": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meno di un secondo"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["meno di ", _interpolate(_named("count")), " secondi"])}
              },
              "x_seconds": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 secondo"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " secondi"])}
              },
              "less_than_x_minutes": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meno di un minuto"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["meno di ", _interpolate(_named("count")), " minuti"])}
              },
              "x_minutes": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 minuto"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " minuti"])}
              },
              "about_x_hours": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["circa un'ora"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["circa ", _interpolate(_named("count")), " ore"])}
              },
              "x_days": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 giorno"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " giorni"])}
              },
              "about_x_months": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["circa un mese"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["circa ", _interpolate(_named("count")), " mesi"])}
              },
              "x_months": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mese"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " mesi"])}
              },
              "about_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["circa un anno"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["circa ", _interpolate(_named("count")), " anni"])}
              },
              "over_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oltre un anno"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["oltre ", _interpolate(_named("count")), " anni"])}
              },
              "almost_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["circa 1 anno"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["circa ", _interpolate(_named("count")), " anni"])}
              },
              "x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 anno"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " anni"])}
              },
              "x_years_or_more": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 anno o più"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " anni o più"])}
              },
              "x_years_and_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " anni e ", _interpolate(_named("days")), " giorni"])}
            }
          },
          "status": {
            "already_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta già acquistata"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallito"])},
            "in_another_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta già presente in un altro carrello"])},
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In elaborazione"])},
            "to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In elaborazione"])},
            "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validato"])}
          },
          "copies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("copies")), " copie"])},
          "lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("lines")), " righe"])},
          "copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("copies")), " copia"])},
          "line": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("lines")), " riga"])},
          "cart_item": {
            "status": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
              "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
              "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
              "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In corso"])},
              "to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In corso"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validato"])}
            }
          },
          "total_with_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo TTC"])},
          "cart_validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convalidare il carrello"])},
          "back_button_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei carrelli"])},
          "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni"])},
          "cart_destroy_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler suprimir questo carrello?"])},
          "cart_reprocess_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reprocess this cart?"])},
          "actions": {
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le panier"])},
            "delete_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
            "export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter en format CSV"])},
            "export_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter en format Excel"])},
            "import_isbns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des ISBN"])},
            "move_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])},
            "move_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer dans un panier"])},
            "reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revalider le panier"])},
            "duplicate_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer le panier"])},
            "resend_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le courriel de confirmation"])},
            "show_processing_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les logs du traitement"])},
            "process_a_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process a refund"])},
            "show_cart_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart history"])}
          },
          "paper_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN cartaceo:"])},
          "offer_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN dell'offerta:"])},
          "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
          "offer": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome utente"])}
          },
          "form": {
            "move_items": {
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le offerte selezionate sono state trasferite nel seguente carrello"])},
              "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected offers have been copied to the following cart"])}
            },
            "destination_cart_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo carrello"])},
            "current_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrelli già esistenti"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
            "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia"])},
            "libraries_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrelli della bibliotecaa"])},
            "my_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei carrelli"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
          },
          "header": {
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
            "move_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sposta elementi"])},
            "copy_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia"])},
            "select_destination_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un carrello"])},
            "sort_by_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per"])},
            "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare gli elementi selezionati?"])},
            "labels": {
              "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro / offerta"])},
              "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo per unità"])},
              "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
              "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di pubblicazione"])},
              "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
              "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])}
            },
            "sort": {
              "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
              "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato"])},
              "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di pubblicazione"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
            }
          },
          "cart_item_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
          "cart_item_details_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli dell'offerta"])},
          "cart_item_details_modal_ownership_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership model"])},
          "cart_item_details_modal_ownership_text_html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This offer is not a license. The purchasing library owns the digital copy of the title they acquire. To review the rights and responsibilities that ownership confers, please see these <a href=\"https://ebooks.dp.la/digital-ownership-agreement/\" target=\"_blank\">detailed terms of the DPLA Ownership model</a>. To learn more, please consult these <a href=\"https://ebooks.dp.la/ownership/\" target=\"_blank\">Frequently Asked Questions</a>."])},
          "cart_item_details_modal_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato"])},
          "cart_item_details_modal_file_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di file"])},
          "cart_item_details_modal_loan_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole di prestito"])},
          "cart_item_details_modal_loan_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di prestiti"])},
          "cart_item_details_modal_concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti simultanei"])},
          "cart_item_details_modal_license_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata della licenza"])},
          "cart_item_details_modal_license_duration_for_ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer duration"])},
          "cart_item_details_modal_license_unlimited_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited duration"])},
          "cart_item_details_modal_loan_maximum_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata massima del prestito"])},
          "cart_item_details_modal_streaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming"])},
          "cart_item_details_modal_streaming_concurrent_users_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di utenti simultanei"])},
          "cart_item_details_modal_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protezione"])},
          "cart_item_details_modal_protection_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di protezione"])},
          "cart_item_details_modal_authorized_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivi autorizzati"])},
          "cart_item_details_modal_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia/incolla"])},
          "cart_item_details_modal_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stampa"])},
          "ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership"])},
          "there_is_no_offer_in_this_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non ci sono articoli nel tuo carrello."])},
          "carts": {
            "group_purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo libro è incluso nella collezione condivisa"])},
            "list_items": {
              "ajax_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci dispiace, si è verificato un errore. La pagina sarà ricaricata."])},
              "confirm_delete_titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desideri veramente eliminare questi titoli?"])},
              "one_title_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi selezionare almeno un titolo da eliminare"])}
            },
            "lists": {
              "titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titles"])}
            },
            "currencies": {
              "CAD": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " $ CAD"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$ CAD"])}
              },
              "CHF": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " CHF"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHF"])}
              },
              "EUR": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " €"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])}
              },
              "GBP": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " £"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["£"])}
              },
              "USD": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$ ", _interpolate(_named("amount"))])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])}
              },
              "ARS": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " $ ARS"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$ ARS"])}
              }
            },
            "details": {
              "loans_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 prestito"])},
              "loans_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " prestiti"])},
              "loans_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestiti illimitati"])},
              "loans_concurrent_users_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno alla volta"])},
              "loans_concurrent_users_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " prestiti simultanei"])},
              "loans_concurrent_users_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illimitati"])},
              "loans_lifetime_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata illimitata"])},
              "x_days_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 giorno"])},
              "x_days_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " giorni"])},
              "x_years_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 anno"])},
              "x_years_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " anni"])},
              "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
              "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illimitato"])}
            },
            "service_fees": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accesso a pagamento e protezioni per gli eBook e gli audiolibri (", _interpolate(_named("rate")), "%)"])},
            "sub_total_without_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotale senza tasse"])},
            "total_by_currency": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Totale ", _interpolate(_named("currency"))])},
            "total_without_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale tasse escluse"])},
            "tax_rates": {
              "gst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GST"])},
              "hst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HST"])},
              "pst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PST"])},
              "qst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QST"])},
              "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])}
            }
          }
        },
        "not_found": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina non trovata"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pagina che stai cercando non esiste."])}
        },
        "components": {
          "title_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
          "default_message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Something went wrong. Please try again in a moment."])},
          "filtrable_select": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select option"])},
            "tag_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to create a tag"])},
            "select_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to select"])},
            "select_group_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to select group"])},
            "selected_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
            "deselect_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to remove"])},
            "deselect_group_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to deselect group"])}
          },
          "catalog_publication": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblicazione del catalogo"])},
            "saved_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni salvate"])},
            "access_all_saved_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi a tutte le impostazioni salvate"])},
            "settings_page": {
              "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
              "loading_creators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading creators..."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved Settings"])},
              "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
              "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Settings"])},
              "messages": {
                "destroy_settings": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
                  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings successfully deleted."])}
                }
              },
              "modal": {
                "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remove ", _interpolate(_named("name"))])},
                "confirm_delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this settings? This action cannot be undone."])},
                "button": {
                  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
                  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
                }
              },
              "role": {
                "name": {
                  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
                  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
                  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
                  "librarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Librarian"])},
                  "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
                  "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])}
                }
              },
              "filters": {
                "options": {
                  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
                },
                "select": {
                  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
                  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
                  "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per page"])}
                },
                "input": {
                  "search": {
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name or creator"])},
                    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
                  }
                },
                "button": {
                  "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])}
                }
              },
              "table": {
                "column": {
                  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
                  "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
                  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
                  "general_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
                  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
                },
                "button": {
                  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
                  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
                  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])}
                }
              }
            },
            "isbn_form": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new ISBNs separated by a space, comma or semicolon"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBNs"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inject ISBNs"])},
              "addButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
              "duplicateTagText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate ISBN"])},
              "limitTagsText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Limit reached (max. ", _interpolate(_named("maxTags")), ")"])},
              "buttons": {
                "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
                "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])}
              }
            },
            "isbn_results": {
              "result_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("of")), "/", _interpolate(_named("from")), " ISBNs found"])},
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ISBNs found"])},
              "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
              "items_not_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("items")), " not found"])},
              "copy_not_found_isbns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy ISBNs that cannot be found"])}
            },
            "item_actions": {
              "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento..."])},
              "resynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La risincronizzazione è stata messa in coda. Si prega di attendere qualche minuto affinché il server elabori la richiesta."])},
              "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina"])},
              "out_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di"])},
              "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati"])},
              "lines_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Righe selezionate"])},
              "clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella selezione"])},
              "search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risultati della ricerca"])},
              "show_full_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra la query completa"])},
              "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementi"])},
              "dropdown_export_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esporta i risultati"])},
              "dropdown_inject_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci una lista di ISBN"])},
              "dropdown_add_selection_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi la selezione a una lista"])},
              "dropdown_apply_metadata_to_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica i metadati alla selezione"])},
              "dropdown_synchronize_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sincronizza la selezione con i metadati del magazzino"])}
            },
            "availabilities": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilità"])},
              "customize_availability_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizza le impostazioni di disponibilità"])},
              "networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reti"])},
              "territories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territori"])},
              "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercati"])},
              "connectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connettori"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predefinito"])}
            },
            "filters": {
              "libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteca"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
              "publication_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di pubblicazione"])},
              "catalog_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di catalogazione"])},
              "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo aggiornamento"])},
              "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornitore"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
              "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilità"])},
              "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile"])},
              "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponibile"])},
              "unavailable__publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editore"])},
              "unavailable__banned_not_epub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietato (non EPUB)"])},
              "unavailable__banned_publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietato (editore)"])},
              "unavailable__date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
              "unavailable__cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copertina"])},
              "unavailable__duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
              "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato"])},
              "catalogued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogato"])},
              "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
              "influence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influenza"])},
              "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblico"])},
              "title_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo e sottotitolo"])},
              "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore"])},
              "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editore"])},
              "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collezione"])},
              "type_to_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digita per cercare"])},
              "save_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva le impostazioni"])},
              "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])},
              "serie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
              "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predefinito"])},
              "option_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
              "option_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
              "pick_several_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezione multipla"])},
              "market_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteche"])},
              "market_retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivenditore"])},
              "market_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteche scolastiche"])},
              "market_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letture in classe"])},
              "market_academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteche universitarie"])},
              "market_corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteche di società"])},
              "catalog_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di catalogo"])},
              "catalog_type_traditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editoria tradizionale"])},
              "catalog_type_self_published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-pubblicazione"])},
              "catalog_type_public_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblico dominio"])},
              "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
              "offer_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID dell'offerta"])},
              "publishing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo editoriale"])},
              "last_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo aggiornamento"])},
              "accessibility_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri di accessibilità"])},
              "accessibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibilità"])},
              "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
              "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista curata"])},
              "award": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premio"])},
              "from_quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editore del Québec"])},
              "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copertina?"])},
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non trovato"])},
              "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibilità"])},
              "fully_accessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interamente accessibile"])},
              "screen_reader_friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idoneo per i lettori di schermo"])},
              "full_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio integrale"])},
              "synchronized_text_and_audio_playback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettura sincronizzata del testo e dell'audio"])},
              "sorter_columns_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica le colonne visibili"])},
              "sorter_filters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i filtri visibili"])},
              "sorter_description_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare clic sui pulsanti in basso per nascondere o visualizzare le colonne corrispondenti."])},
              "sorter_description_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare clic sui pulsanti in basso per nascondere o visualizzare i filtri corrispondenti."])},
              "sorter_description_drag_and_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quindi trascinali e rilasciali nell'ordine desiderato (oppure usa le frecce)."])},
              "sorter_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
              "sorter_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare"])},
              "sorter_change_columns_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia colonne"])},
              "sorter_change_filters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia filtri"])},
              "sorter_reset_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset colonne"])},
              "sorter_change_position_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia posizione delle colonne"])},
              "sorter_reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta i filtri visibilità e posizione"])},
              "sorter_change_position_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia posizione dei filtri"])}
            },
            "save_settings": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva le impostazioni"])},
              "visible_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri visibili"])},
              "filter_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valori del filtro"])},
              "save_filter_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva i valori correnti del filtro"])},
              "change_filter_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia i valori del filtro"])},
              "visible_values_in_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valori visibili nei risultati"])},
              "saved_settings_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome delle impostazioni salvate"])},
              "saved_settings_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di impostazioni salvate"])},
              "personal_settings_only_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo tu avrai accesso"])},
              "personal_settings_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli utenti con il tuo ruolo"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
              "error_unable_to_save_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile salvare le impostazioni"])}
            }
          }
        },
        "store_item_export": {
          "component": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export results"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select / unselect the values in the following list to define which colums and values will be in the export."])},
            "export_buttons_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select, using the buttons below, the columns you want to export."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values to export"])},
            "buttons": {
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
              "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
            }
          },
          "request_export": {
            "message": {
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report is being generated. You will receive an email when it is ready."])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while processing the request, please try again later."])}
            }
          },
          "columns": {
            "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover url"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title & subtitle"])},
            "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication date"])},
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author(s)"])},
            "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
            "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
            "influence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influences"])},
            "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
            "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
            "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
            "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
            "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Series"])},
            "from_quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Quebec"])}
          },
          "is_from_quebec": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
          }
        }
      }
    }
  })
}
